import useAuth from "../context/User/useAuth";
import useHome from "../feature/Home/useHome";
import Jobs from "../feature/Home/Jobs";
import Customers from "../feature/Home/Customers";
import Products from "../feature/Home/Products";
import Steps from "../feature/Home/Steps";
import TouchLog from "../feature/Home/TouchLog";

function Home() {

  const page = useHome();
  const body = page.state.body;

  const employee = useAuth().auth.employee;

  return (
    <div className="column">
      {body.welcome.show === true ? (
        <>
          <h1>Welcome to Ink Strategies</h1>
          <h3>{employee?.firstName}</h3>
          <b>Select an assigned job or choose a customer</b>
        </>
      ) : (
        <></>
      )}

      <div className="list">
        {body.customers.show === true ? <Customers page={page} /> : <></>}
        {body.jobs.show === true ? (
          <Jobs page={page} employee={employee} />
        ) : (
          <></>
        )}
        {body.products.show === true ? <Products page={page} /> : <></>}
        {body.steps.show === true ? <Steps page={page} /> : <></>}
        {body.log.show === true ? (
          <TouchLog page={page} employee={employee} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default Home;
