import { useCallback, useReducer, useEffect, useRef, useState } from "react";

import initialize from "./initial";

//TODO::
//determine a way to initialize object with extra properties but
//then have the choice to update the initail state for use with
//reset and init
function usePage(initialState, signalState) {
  //ANCILLARY PROPERTIES
  initialize(initialState);
  //COPY OF INITIAL STATE
  const [initial, setInit] = useState(initialState);
  //CRUD STATE
  const [crudFunctions, setCrudFunctions] = useState({});
  const [crudCallback, setCrudCallback] = useState({});

  //ERROR HANDLING
  const checkReserved = (path) => {
    let check = true;
    if (path === undefined) {
      return (check = true);
    }
    const writeOnly = ["id", "path", "initial"];
    if (writeOnly.includes(path)) {
      return (check = false);
    }
    const route = path.split(".");

    route.forEach((prop) => {
      if (writeOnly.includes(prop)) {
        console.error(
          `The property ${prop} in ${path} is reserved in usePage and cannot be used`
        );
        check = false;
      }
    });
    return check;
  };

  //UTILITY
  const findAnyFirstInstance = (obj, prop, target) => {
    let result = null;
    const search = (data) => {
      if (result) return; // If result is shadow, exit the search
      if (data === null || data === undefined) return;
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          data.forEach((el) => {
            if (typeof el === "object" && !Array.isArray(el)) {
              search(el);
            }
          });
        } else {
          for (const [key, value] of Object.entries(data)) {
            if (key === prop && value === target) {
              result = data;
              return;
            }
            if (typeof value === "object") {
              search(value);
            }
          }
        }
      }
    };

    search(obj);
    return result;
  };

  const aggregateErrors = (obj) => {
    let error = false;
    let errorPath = "";
    const search = (data, path = "") => {
      if (error) return;
      if (data === null || data === undefined) return;
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          data.forEach((el, index) => {
            if (typeof el === "object" && !Array.isArray(el)) {
              search(el, `${path}[${index}]`);
            }
          });
        } else {
          for (const [key, value] of Object.entries(data)) {
            const newPath = path ? `${path}.${key}` : key;
            if (/error/.test(key) && value === true) {
              error = true;
              errorPath = newPath;
              return;
            }
            if (typeof value === "object") {
              search(value, newPath);
            }
          }
        }
      }
    };
    search(obj);
    return { error, errorPath };
  };

  const select = (state, root, obj) => {
    let clone = { ...state };
    let start = traverse(clone, root);
    const search = (data) => {
      if (data === null || data === undefined) return;
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          data.forEach((el, index) => {
            if (typeof el === "object" && !Array.isArray(el)) {
              search(el);
            }
          });
        } else {
          for (const [key, value] of Object.entries(data)) {
            for (const [k, v] of Object.entries(obj)) {
              if (key === k) {
                data[key] = v;
                // delete obj[k];
              }
            }

            if (typeof value === "object") {
              search(value);
            }
          }
        }
      }
    };
    search(start);
    return start;
  };

  //follows the string path to the end,
  //and constructs the object along the way,
  //returns the object with modified value at the end of the path
  const recurse = (prop, path, payload) => {
    let clone = Array.isArray(prop) ? [...prop] : { ...prop };
    let pathArr = path.split(".");
    let key = pathArr.shift();

    if (pathArr.length === 0) {
      clone[key] = payload;
    } else {
      if (/\d+/.test(key)) {
        key = parseInt(key);
      }
      clone[key] = clone[key] || {};
      clone[key] = recurse(clone[key], pathArr.join("."), payload);
    }
    return clone;
  };

  //returns the value at the end of the path
  const traverse = (obj, path) => {
    let clone = { ...obj };
    const route = path.split(".");
    for (let i = 0; i < route.length; i++) {
      clone = clone[route[i]];
    }
    return clone;
  };

  //returns the state with the updated value at the end of the path
  const target = (state, path, value) => {
    if (path === undefined) {
      return value;
    }
    const route = path.split(".");
    const root = route[0];
    const loc = route.slice(1).join(".");
    if (route.length === 1) {
      return { ...state, [root]: value };
    }
    let update = recurse(state[root], loc, value);
    return { ...state, [root]: update };
  };

  //seperate here to allow for useCallback to work
  const type = (state, type, path, value, obj) => {
    if (type === "set") {
      return value;
    }

    if (type === "selectiveSet") {
      let clone = JSON.parse(JSON.stringify(state));
      let result = select(clone, path, value);
      return result;
    }

    if (type === "error") {
      return value;
    }

    if (type === "init") {
      let clone = JSON.parse(JSON.stringify(value));
      let reinit = obj?.reinitialize ?? false;
      let store = obj?.store ?? true; //we need this
      //for instances were we don't want to reset the
      // initial state and just apply the extra properties
      initialize(clone, path, reinit);
      if (!store) return clone;
      let init = target({ ...initial }, path, clone);
      setInit(init);
      return clone;
    }

    if (type === "reset") {
      if (path === undefined) {
        return initial;
      } else {
        let clone = { ...initial };
        let target = traverse(clone, path);
        return target;
      }
    }

    if (type === "toggle") {
      let target = !traverse(state, path);
      return target;
    }

    if (type === "add") {
      let clone = { ...state };
      let target = traverse(clone, path);
      return initialize([...target, value], path);
    }

    if (type === "remove") {
      let clone = { ...state };
      let target = traverse(clone, path);
      let final = target.filter((obj, i) => {
        return i !== parseInt(value);
      });
      return initialize(final, path, { reinitialize: true });
    }

    if (type === "ping") {
      let clone = { ...state };
      let target = traverse(clone, path);
      return { on: !target.on, data: value };
    }
  };

  //REDUCER
  const reducer = (state, action) => {
    //functional set
    if (typeof action.value === "function" && action.type === "set") {
      let clone = { ...state };
      let current = traverse(clone, action.path);
      action.value = action.value(current);
    }
    if (!checkReserved(action.path)) {
      return state;
    } else {
      let update = type(
        state,
        action.type,
        action.path,
        action.value,
        action.obj
      );

      return target({ ...state }, action.path, update);
    }
  };

  //DISPATCH
  const [state, dispatch] = useReducer(reducer, initialState);

  const [signal, signalDispatch] = useReducer(reducer, signalState);

  //ACTIONS
  const set = useCallback((path, value) => {
    dispatch({ type: "set", path: path, value: value });
  }, []);

  //merge this into set at somepoint or define setOne and setMany
  const selectiveSet = useCallback((path, value) => {
    dispatch({ type: "selectiveSet", path: path, value: value });
  }, []);

  const find = useCallback((id) => {
    return findAnyFirstInstance(shadow.current, "id", id);
  }, []);

  const path = useCallback((path) => {
    return traverse(shadow.current, path);
  }, []);

  const init = useCallback((path, value, obj) => {
    dispatch({ type: "init", path: path, value: value, obj: obj });
  }, []);

  const toggle = useCallback((path) => {
    dispatch({ type: "toggle", path: path });
  }, []);

  const add = useCallback((path, value) => {
    dispatch({ type: "add", path: path, value: value });
  }, []);

  const remove = useCallback((path, index) => {
    dispatch({ type: "remove", path: path, value: index });
  }, []);

  const force = useCallback((path) => {
    let route = `${path}.render`;
    let value = traverse(shadow.current, route);
    dispatch({ type: "set", path: route, value: value + 1 });
  }, []);

  const error = useCallback((path) => {
    let clone = { ...shadow.current };
    let target = traverse(clone, path);
    const { error, errorPath } = aggregateErrors(target);
    return error;
  }, []);

  //TODO:: make load propegate up the object tree
  // if i can do this then we don't need on and set we simple tell it where to put the data
  // and load will affect the chain
  const load = useCallback(async (object) => {
    if (typeof object !== "object") {
      console.error("load must be an object");
      return;
    }
    const { crud, on, set } = object;
    let clone = { ...shadow.current };

    if (traverse(clone, `${on}.loading`) === false) {
      setCrudFunctions((prev) => ({ ...prev, [on]: crud }));
      let response = await crud();
      console.log("loading?");

      dispatch({ type: "set", path: `${on}.loading`, value: true });
      dispatch({ type: "set", path: `${on}.status`, value: "fetching" });
      if (response) {
        setCrudCallback((prev) => ({ ...prev, [on]: set }));
        set(response);
        dispatch({ type: "set", path: `${on}.loading`, value: false });
        dispatch({ type: "set", path: `${on}.status`, value: "loaded" });

      }
    }
  }, []);

  const reload = useCallback(
    async (path) => {
      if (path === undefined) {
        console.log(crudFunctions);
        return;
      }
      let response = await crudFunctions[path]();
      if (response) {
        crudCallback[path](response);
      }
    },
    [crudFunctions, crudCallback]
  );

  const reset = useCallback((path) => {
    dispatch({ type: "reset", path: path });
  }, []);

  const ping = useCallback((path, data) => {
    signalDispatch({ type: "ping", path: path, value: data });
  }, []);

  //this allows access to the most recent state for find() and path()
  //this feels like a cheat
  const shadow = useRef(null);
  useEffect(() => {
    shadow.current = state;
  }, [state]);

  return {
    set,
    selectiveSet,
    find,
    path,
    init,
    toggle,
    add,
    remove,
    force,
    error,
    reset,
    load,
    reload,
    ping,
    state,
    shadow,
    initial,
    signal,
  };
}

export default usePage;
