import useIntercept from "../../context/User/useIntercept";

function useCheckpointCalls() {
  const axiosProtected = useIntercept();

  const postScan = async (data) => {
    try {
      const response = await axiosProtected.post("/stopwatch/scan", {
        data: data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  return { postScan };
}

export default useCheckpointCalls;
