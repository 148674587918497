export const reducer = (state, action) => {
  switch (action.type) {
    case "station":
      window.localStorage.setItem("stationName", action.name);
      window.localStorage.setItem("stationId", action.id);
      return {
        id: action.id,
        name: action.name,
      };

    default:
      return state;
  }
};

export const initialState = {
  id: "",
  name: "",
};
