import { useState, useEffect, useCallback } from "react";
import Pause from "../icons/Pause";
import End from "../icons/End";
import Alert from "../feature/Stopwatch/Alert";
import Timer from "../feature/Stopwatch/Timer";
import useAnimations from "../hooks/useAnimations";
import Spinner from "../icons/Spinner";
import useTouchLog from "../feature/Touchlog/useTouchLog";
import useTouchlogCalls from "../feature/Touchlog/touchlogAxiosFunctions";
import useDBUtilsCalls from "../utils/dbUtilsFunctions";
import useAuth from "../context/User/useAuth";

// EMP >> CLIENT >> PRODUCT >> STEP >> READY

function Touchlog() {
  const { auth, setAuth } = useAuth();
  const [alert, setAlert] = useState({ msg: "", type: "" });
  const { state, dispatch } = useTouchLog();
  const { postLogItem } = useTouchlogCalls();
  const { getClientProducts } = useDBUtilsCalls();

  const callbackAuthSignal = useCallback(() => {
    setAuth({ ...auth, signal: false });
  }, [auth, setAuth]);

  //bull shit work around for react-router-dom
  useEffect(() => {
    if (auth.signal === true) {
      dispatch({ type: "reload" });
      callbackAuthSignal();
    }
  }, [dispatch, callbackAuthSignal, auth.signal]);

  const callbackTick = useCallback(() => {
    const tick = (now) => {
      let remain = now;
      let hours = Math.floor(remain / 3600);
      remain -= hours * 3600;
      let mins = Math.floor(remain / 60);
      remain -= mins * 60;
      let secs = remain;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (mins < 10) {
        mins = "0" + mins;
      }
      if (secs < 10) {
        secs = "0" + secs;
      }
      dispatch({ type: "clock", payload: `${hours}:${mins}:${secs}` });
    };
    tick(state.seconds);
  }, [dispatch, state.seconds]);

  // Start Clock
  useEffect(() => {
    let interval = null;
    if (state.clockState.tock) {
      interval = setInterval(() => {
        let next = state.seconds + 1;
        dispatch({ type: "seconds", payload: next });
        // tick(state.seconds);
        callbackTick();
      }, 1000);
    } else if (!state.clockState.tock && state.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [state.clockState, state.seconds, callbackTick, dispatch]);

  const {
    animationState,
    animateStopWatch,
    animateScan,
    animateSuccess,
    animateEmp,
  } = useAnimations();

  const renderStages = () => {
    switch (state.stage) {
      case "employee":
        return employeeSelect();
      // case "client":
      //   return clientSelect();
      case "product":
        return productSelect();
      case "step":
        return stepSelect();
      case "ready":
        return interfaceDisplay();
      default:
        return <></>;
    }
  };

  const employeeSelect = () => {
    return state?.employeeList?.map((emp) => {
      return (
        <button
          className="btn-default btn-factory"
          key={emp._id}
          id={emp._id}
          value={emp._id}
          onClick={async () => {
            //HARDCODE:: this is here temporarily until we need a more versitile touchlog
            const client = state.clientStore.find(
              (client) => client.clientName === "High Tail Designs"
            );
            const productIds = client?.products?.map((product) => {
              return product.id;
            });
            const data = await getClientProducts(productIds);
            if (data) {
              dispatch({
                type: "state",
                payload: {
                  ...state,
                  stage: "product",
                  productStore: data,
                  employee: {
                    id: emp.empId,
                    name: `${emp.firstName} ${emp.lastName}`,
                  },
                },
              });
            }
          }}
        >
          {`${emp.firstName} ${emp.lastName}`}
        </button>
      );
    });
  };

  const clientSelect = () => {
    return state?.clientStore?.map((client) => {
      return (
        <button
          className="btn-default btn-factory"
          key={client._id}
          id={client._id}
          value={client._id}
          onClick={() => {
            handleOnChooseClient(client._id);
          }}
        >
          {client.clientName}
        </button>
      );
    });
  };

  const handleOnChooseClient = async (id) => {
    const client = state.clientStore.find((client) => client._id === id);
    const productIds = client?.products?.map((product) => {
      return product.id;
    });
    const data = await getClientProducts(productIds);
    if (data) {
      dispatch({
        type: "state",
        payload: {
          ...state,
          stage: "product",
          productStore: data,
        },
      });
    }
  };

  const productSelect = () => {
    return state.productStore?.map((product) => {
      return (
        <button
          className="btn-default btn-factory"
          key={product._id}
          id={product._id}
          value={product._id}
          onClick={() => {
            handleOnChooseProduct(product._id);
          }}
        >
          {product.name}
        </button>
      );
    });
  };

  const handleOnChooseProduct = (_id) => {
    const product = state.productStore.find((product) => product._id === _id);
    const steps = product?.steps?.map((step) => {
      return step;
    });
    dispatch({
      type: "state",
      payload: {
        ...state,
        stage: "step",
        stepStore: steps,
      },
    });
  };

  const stepSelect = () => {
    return state?.stepStore?.map((step) => {
      return (
        <button
          className="btn-default btn-factory"
          key={step._id}
          id={step._id}
          value={step._id}
          onClick={() => {
            handleOnChooseStep(step);
          }}
        >
          {step.name}
        </button>
      );
    });
  };

  const handleOnChooseStep = (step) => {
    const payload = {
      ...state,
      stage: "ready",
      paused: true,
      stepInfo: step,
      seconds: 0,
      clock: "00:00:00",
      clockState: { ...state.clockState, tock: false },
      sessionCount: 0,
    };
    animateScan();
    dispatch({ type: "state", payload: payload });
  };

  const generateBodyData = () => {
    const milli = Date.now();
    const date = new Date(milli);
    const now = date.toISOString();
    const bodyData = {
      stepName: state.stepInfo.name,
      stepId: state.stepInfo._id,
      date: now,
      time: state.seconds,
      empId: state.employee.id,
      empName: state.employee.name,
      stationName: state.stepInfo.station,
      stationId: state.stepInfo.stationId,
    };
    return bodyData;
  };

  const logItem = async () => {
    const bodyData = generateBodyData();
    const data = await postLogItem(bodyData);
    return data;
  };

  const handleOnClick = () => {
    animateScan();
    animateSuccess();
    animateStopWatch();
    if (state.paused === true) {
      const payload = {
        ...state,
        paused: false,
        clockState: { ...state.clockState, tock: true },
      };
      setAlert({ msg: "", type: "alert-success" });
      dispatch({
        type: "state",
        payload: payload,
      });
    } else {
      const count = state.sessionCount + 1;
      const payload = {
        ...state,
        clockState: { ...state.clockState, tock: true },
        sessionCount: count,
        seconds: 0,
        clock: "00:00:00",
      };
      dispatch({
        type: "state",
        payload: payload,
      });
      setAlert({ msg: "", type: "alert-success" });
      logItem();
    }
  };

  const handleOnPause = (e) => {
    e.preventDefault();
    if (state.clockState.tock) {
      animateScan();
      animateEmp();
      setAlert({ msg: "Paused", type: "alert-success" });
      const payload = {
        ...state,
        paused: true,
        clockState: { ...state.clockState, tock: false },
      };
      dispatch({
        type: "state",
        payload: payload,
      });
    }
  };

  const handleOnLast = (e) => {
    e.preventDefault();
    if (state.clockState.tock) {
      animateScan();
      animateEmp();
      setAlert({ msg: "Finished", type: "alert-success" });
      const payload = {
        ...state,
        clockState: { ...state.clockState, tock: false },
        seconds: 0,
        clock: "00:00:00",
        sessionCount: 0,
      };
      dispatch({
        type: "state",
        payload: payload,
      });
      logItem();
    }
  };

  const interfaceDisplay = () => {
    if (state.stepInfo !== "") {
      return (
        <div className="bar">
          <button
            className="btn-default btn-pause"
            onClick={(e) => {
              handleOnPause(e);
            }}
          >
            <Pause />
          </button>
          <Timer
            timer={state.clock}
            animationState={animationState}
            width={"100%"}
            action={handleOnClick}
          />
          <button
            className="btn-default btn-end"
            onClick={(e) => {
              handleOnLast(e);
            }}
          >
            <End />
          </button>
        </div>
      );
    }
  };

  //REACT COMPONENT
  return (
    <div className="column">
      <div className="column almostfull list shadow">
        <div className="bar split soft item">
          <h1 className="item">{state?.stepInfo?.name}</h1>
          <div className="column c-right">
            <h3 className="item">User</h3>
            <h5 className="item">{state.employee?.name}</h5>
          </div>
        </div>
        <Alert msg={alert.msg} type={alert.type} />
        {state.status !== "finished" ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            <div className="card center">{renderStages()}</div>

            <div className="column c-right">
              <h5 className="item">Session Count</h5>
              <h6 className="item ">{state?.sessionCount}</h6>
            </div>

            <section className="bar list split">
              <div className="column c-left">
                <h2 className="item">Product Info</h2>
                <div className={"item"}> {state.stepInfo?.text}</div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}
export default Touchlog;
