import { useReducer, useEffect, useCallback } from "react";

import useDBUtilsCalls from "../../utils/dbUtilsFunctions";

function useTouchLog() {
  const { getEmployees, getClients } = useDBUtilsCalls();

  const loadState = {
    employee: "idle",
    products: "idle",
    clients: "idle",
  };

  function loadReducer(state, action) {
    switch (action.type) {
      case "reset":
        return loadState;
      case "employee":
        return { ...state, employee: action.payload };
      case "clients":
        return { ...state, clients: action.payload };
      default:
        return state;
    }
  }

  const [isLoading, loadDispatch] = useReducer(loadReducer, loadState);

  const initialState = {
    status: "idle",
    stage: "employee",
    paused: true,
    clockState: { login: false, tock: false },
    scan: "",
    clock: "00:00:00",
    seconds: 0,
    employee: { id: "", name: "Select user to sign in" },
    employeeList: [],
    currentItem: { id: "", name: "", sku: "" },
    previousItem: { id: "", name: "", sku: "" },
    stepInfo: "",
    sessionCount: 0,
    stepList: [],
    productStore: [],
    clientStore: [],
  };

  const recurse = (prop, path, payload) => {
    let pathArr = path.split(".");
    let key = pathArr.shift();
    if (pathArr.length === 0) {
      prop[key] = payload;
    } else {
      prop[key] = prop[key] || {};
      recurse(prop[key], pathArr.join("."), payload);
    }
    return prop;
  };

  function reducer(state, action) {
    if (action.type === "reload") {
      loadDispatch({ type: "reset" });
      return initialState;
    }
    if (action.type === "reset") return initialState;
    if (action.type === "state") return action.payload;
    if (typeof action !== "object") return state;
    if (action.type.indexOf(".") === -1) {
      return { ...state, [action.type]: action.payload };
    }
    const root = action.type.split(".")[0];
    const path = action.type.split(".").slice(1).join(".");
    const payload = action.payload;
    return {
      ...state,
      [root]: recurse(state[root], path, payload),
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const callbackEmployee = useCallback(async () => {
    return await getEmployees();
  }, [getEmployees]);

  // Load employee List
  useEffect(() => {
    async function employees() {
      if (isLoading.employee === "finished" || isLoading.employee === "loading")
        return;
      loadDispatch({ type: "employee", payload: "loading" });
      const empList = await callbackEmployee();
      if (empList) {
        loadDispatch({ type: "employee", payload: "finished" });
        dispatch({ type: "employeeList", payload: empList });
      }
    }
    employees();
  }, [isLoading.employee, callbackEmployee]);

  const callbackClient = useCallback(async () => {
    return await getClients();
  }, [getClients]);

  // Load client List
  useEffect(() => {
    async function clients() {
      if (isLoading.clients === "finished" || isLoading.clients === "loading")
        return;
      loadDispatch({ type: "clients", payload: "loading" });
      const client = await callbackClient();
      if (client) {
        loadDispatch({ type: "clients", payload: "finished" });
        dispatch({ type: "clientStore", payload: client });
      }
    }
    clients();
  }, [isLoading.clients, callbackClient]);

  //Load Page
  useEffect(() => {
    if (isLoading.employee === "finished" && isLoading.clients === "finished") {
      dispatch({ type: "status", payload: "finished" });
    }
  }, [isLoading.employee, isLoading.clients]);

  return { state, dispatch };
}

export default useTouchLog;
