import { useEffect, useReducer, useCallback } from "react";
import useListCalls from "./useListCalls";

function useList() {
  const loadingState = {
    station: "idle",
  };

  const loadingReducer = (state, action) => {
    switch (action.type) {
      case "reset":
        return loadingState;
      case "station":
        return { ...state, station: action.payload };
      default:
        return state;
    }
  };
  const [isLoading, loadDispatch] = useReducer(loadingReducer, loadingState);

  const initialState = {
    stations: [],
  };

  const recurse = (prop, path, payload) => {
    let pathArr = path.split(".");
    let key = pathArr.shift();
    if (pathArr.length === 0) {
      prop[key] = payload;
    } else {
      prop[key] = prop[key] || {};
      recurse(prop[key], pathArr.join("."), payload);
    }
    return prop;
  };

  function reducer(state, action) {
    if (action.type === "reload") {
      loadDispatch({ type: "reset" });
      return initialState;
    }
    if (action.type === "reset") return initialState;
    if (action.type === "state") return action.payload;
    if (typeof action !== "object") return state;
    if (action.type.indexOf(".") === -1) {
      return { ...state, [action.type]: action.payload };
    }
    const root = action.type.split(".")[0];
    const path = action.type.split(".").slice(1).join(".");
    const payload = action.payload;
    return {
      ...state,
      [root]: recurse(state[root], path, payload),
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { getStations } = useListCalls();

  const callbackStations = useCallback(async () => {
    return await getStations();
  }, [getStations]);

  useEffect(() => {
    async function effect() {
      if (isLoading.station === "finished" || isLoading.station === "loading")
        return;
      loadDispatch({ type: "station", payload: "loading" });
      const data = await callbackStations();
      if (data) {
        const station = data?.filter(
          (item) => item.stationType !== "touch_log"
        );
        loadDispatch({ type: "station", payload: "finished" });
        dispatch({ type: "stations", payload: station });
      }
    }
    effect();
  }, [isLoading.station, callbackStations]);

  return { state, dispatch };
}
export default useList;
