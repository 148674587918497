import { useReducer } from "react";

function useStopWatch() {
  const initialState = {
    clockState: { login: false, tock: false },
    scan: "",
    clock: "00:00:00",
    seconds: 0,
    employee: { id: "", name: "Scan name to sign in" },
    currentItem: { id: "", name: "", sku: "" },
    previousItem: { id: "", name: "", sku: "" },
    productInfo: [],
    sessionCount: 0,
  };

  const recurse = (prop, path, payload) => {
    let pathArr = path.split(".");
    let key = pathArr.shift();
    if (pathArr.length === 0) {
      prop[key] = payload;
    } else {
      prop[key] = prop[key] || {};
      recurse(prop[key], pathArr.join("."), payload);
    }
    return prop;
  };

  function reducer(state, action) {
    if (action.type === "reset") return initialState;
    if (action.type === "state") return action.payload;
    if (typeof action !== "object") return state;
    if (action.type.indexOf(".") === -1) {
      return { ...state, [action.type]: action.payload };
    }
    const root = action.type.split(".")[0];
    const path = action.type.split(".").slice(1).join(".");
    const payload = action.payload;
    return {
      ...state,
      [root]: recurse(state[root], path, payload),
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
}

export default useStopWatch;
