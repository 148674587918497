import Station from "../components/Station";
import useList from "../feature/List/useList";

function Stations() {
  const { state } = useList();

  // Add new station types here
  const stationLookup = {
    stop_watch: "stopwatch",
    check_point: "checkpoint",
    touch_log: "touchlog",
  };

  return (
    <>
      <div className="column ">
        <div className="card thirds shadow">
          {state.stations?.map((value) => {
            return (
              <Station
                key={value._id}
                type={stationLookup[value.stationType]}
                name={value.stationName}
                css={"btn-factory"}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
export default Stations;
