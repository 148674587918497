import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { StationContext } from "../context/Station/StationProvider";

function Station(props) {
  const { type, name, id } = props;

  const [state, dispatch] = useContext(StationContext);
  const navigate = useNavigate();

  const handleChange = () => {
    dispatch({ type: "station", name, id });
    navigate(`/${type}`);
  };

  return (
    <button
      className="btn-default btn-factory"
      onClick={(e) => {
        handleChange();
      }}
    >
      {name}
    </button>
  );
}
export default Station;
