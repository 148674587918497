import useIntercept from "../../context/User/useIntercept";

function useTouchlogCalls() {
  const axiosProtected = useIntercept();

  const postLogItem = (logItem) => {
    try {
      const { data } = axiosProtected.post("/touchlog/tap", { data: logItem });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  return { postLogItem };
}

export default useTouchlogCalls;
