import { useState, useEffect, useCallback } from "react";
import useServer from "../../hooks/useServer";
import Alert from "../Stopwatch/Alert";
import Pause from "../../icons/Pause";
import End from "../../icons/End";
import Timer from "../Stopwatch/Timer";
import useAnimations from "../../hooks/useAnimations";
import HiddenBarcodeReader from "../Stopwatch/HiddenBarcodeReader";
function TouchLog(props) {
  const { page, employee } = props;
  const [alert, setAlert] = useState({ msg: "", type: "" });
  const { post } = useServer();
  const current = page.state.body.log.current;
  const log = page.state.body.log;

  const { set } = page;
  const {
    animationState,
    animateStopWatch,
    animateScan,
    animateSuccess,
    animateEmp,
    animateError,
  } = useAnimations();

  //EFFECTS
  const callbackTick = useCallback(() => {
    const tick = (now) => {
      let remain = now;
      let hours = Math.floor(remain / 3600);
      remain -= hours * 3600;
      let mins = Math.floor(remain / 60);
      remain -= mins * 60;
      let secs = remain;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (mins < 10) {
        mins = "0" + mins;
      }
      if (secs < 10) {
        secs = "0" + secs;
      }
      set("body.log.clock", `${hours}:${mins}:${secs}`);
    };
    tick(log.seconds);
  }, [set, log.seconds]);

  // Start Clock
  useEffect(() => {
    let interval = null;
    if (log.clockState.tock) {
      interval = setInterval(() => {
        let next = log.seconds + 1;
        set("body.log.seconds", next);
        callbackTick();
      }, 1000);
    } else if (!log.clockState.tock && log.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [log, callbackTick]);

  //FUNCTIONS
  const pause = (e) => {
    e.preventDefault();
    if (log.clockState.tock) {
      animateScan();
      animateEmp();
      setAlert({ msg: "Paused", type: "alert-success" });
      page.set("body.log.pause", true);
      page.set("body.log.clockState", { ...log.clockState, tock: false });
    }
  };

  const unpause = (e) => {
    e.preventDefault();
    if (log.pause === true) {
      setAlert({ msg: "", type: "alert-success" });
      animateScan();
      animateSuccess();
      animateStopWatch();
      page.set("body.log.clockState", { ...log.clockState, tock: true });
    }
    page.set("body.log.pause", false);
  };

  const goBack = () => {
    if (log.seconds > 0) {
      logItem(log.sessionCount);
    }
    page.set("body.log.clockState", {
      ...log.clockState,
      tock: false,
    });
    page.set("body.log.seconds", 0);
    page.set("body.log.clock", "00:00:00");
    page.set("body.log.sessionCount", 0);
    page.set("body.log.show", false);
    page.set("body.steps.show", true);
  };

  const generateBodyData = () => {
    const milli = Date.now();
    const date = new Date(milli);
    const now = date.toISOString();
    const bodyData = {
      productId: current.productId,
      processId: current.processId,
      itemId: page.state.body.log.logged,
      stepName: current.name,
      stepId: current._id,
      date: now,
      time: log.seconds,
      empId: employee._id,
      empFirstName: employee.firstName,
      empLastName: employee.lastName,
    };
    return bodyData;
  };

  const check_scan_error = async (log, sequence) => {
    const check = await post("/log/item/check", {
      itemId: sequence === "last" ? log.logged : log.scan,
      productId: log.current.productId,
      stepId: log.current._id,
    });
    if (check.type === "error") {
      setAlert({ msg: check.msg, type: "alert-error" });
      page.set("body.log.scan", "");
      animateError();
      return true;
    } else {
      return false;
    }
  };

  const check_same_scan = () => {
    if (page.state.body.log.scan === page.state.body.log.logged) {
      setAlert({ msg: "Doubled Scanned", type: "alert-error" });
      page.set("body.log.scan", "");
      animateError();
      return true;
    } else {
      return false;
    }
  };

  const logItem = async (sequence) => {
    if (check_same_scan()) return;
    if (await check_scan_error(page.state.body.log, sequence)) return;

    console.log(sequence);
    setAlert({ msg: "", type: "" });

    if (sequence === "first") {
      animateScan();
      animateSuccess();
      animateStopWatch();
      page.set("body.log.clockState", { ...log.clockState, tock: true });
      page.set("body.log.logged", page.state.body.log.scan);
      page.set("body.log.scan", "");
      page.set("body.log.sessionCount", 1);
      return;
    }

    const bodyData = generateBodyData();
    const data = await post("/log", bodyData);
    if (data.type === "error") {
      page.set("body.log.scan", "");
      setAlert({ msg: data.msg, type: "alert-error" });
      animateError();
      return;
    }

    if (data.type === "sucess" && sequence === "scan") {
      animateScan();
      animateSuccess();
      animateStopWatch();
      page.set("body.log.logged", page.state.body.log.scan);
      page.set("body.log.scan", "");
      page.set("body.log.seconds", 0);
      page.set("body.log.clock", "00:00:00");
      const count = log.sessionCount + 1;
      page.set("body.log.sessionCount", count);
      return;
    }

    if (data.type === "sucess" && sequence === "last") {
      animateEmp();
      setAlert({ msg: "Finished", type: "alert-success" });
      page.set("body.log.sessionCount", 0);
      page.set("body.log.clockState", { ...log.clockState, tock: false });
      page.set("body.log.scan", "");
      page.set("body.log.logged", "");
      page.set("body.log.seconds", 0);
      page.set("body.log.clock", "00:00:00");
    }
  };

  //COMPONENT
  return (
    <div className="column">
      <h1>{page.state.body.log.current.productName}</h1>
      <div className="column almostfull list shadow soft">
        <div className="card soft item">
          <button
            className="btn-default item"
            onClick={() => {
              goBack();
            }}
          >
            🞀🞀 Steps
          </button>
          <h1 className="item">{current?.name}</h1>
        </div>
        <Alert msg={alert.msg} type={alert.type} />
        <HiddenBarcodeReader
          onSubmit={(e) => {
            e.preventDefault();
            page.state.body.log.sessionCount === 0
              ? logItem("first")
              : logItem("scan");
          }}
          onScan={(e) => {
            page.set("body.log.scan", e.target.value);
          }}
          scan={page.state.body.log.scan}
        />
        <div className="card center">
          <div className="bar">
            <button
              className="btn-default btn-pause"
              onClick={(e) => {
                pause(e);
              }}
            >
              <Pause />
            </button>
            <Timer
              timer={log.clock}
              animationState={animationState}
              width={"100%"}
              action={(e) => {
                unpause(e);
              }}
            />
            <button
              className="btn-default btn-end"
              onClick={(e) => {
                logItem("last");
              }}
            >
              <End />
            </button>
          </div>
        </div>

        <div className="column c-right">
          <h5 className="item">Session Count</h5>
          <h6 className="item ">{log?.sessionCount}</h6>
        </div>

        <section className="bar list split">
          <div className="column c-left">
            <h2 className="item">Product Info</h2>
            <div className={"item"}> {current.desc}</div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default TouchLog;
