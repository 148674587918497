function Clock(props) {
  const { timer, animationState, width } = props;
  const style = { width: `calc(${width} - var(--margin))` };

  return (
    <div className={`sw-time ${animationState.timer}`} style={style}>
      <div className={`column center sw-face ${animationState.face}`}>
        <div className={`sw-timer ${animationState.numbers}`}>{timer}</div>
      </div>
      <div className={`sw-flash ${animationState.flash}`}></div>
    </div>
  );
}

export default Clock;
