import useIntercept from "../context/User/useIntercept";

function useDBUtilsCalls() {
  const axiosProtected = useIntercept();

  const getEmployees = async () => {
    try {
      const { data } = await axiosProtected.get("db/employees");
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const getOneSku = async (sku) => {
    try {
      const { data } = await axiosProtected.post("db/sku", { data: sku });
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const getProducts = async () => {
    try {
      const { data } = await axiosProtected("db/products");
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getClients = async () => {
    try {
      const { data } = await axiosProtected("db/clients");
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const getClientProducts = async (productIds) => {
    try {
      const { data } = await axiosProtected.post("db/clientproducts", {
        data: productIds,
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getEmployees,
    getProducts,
    getOneSku,
    getClients,
    getClientProducts,
  };
}

export default useDBUtilsCalls;
