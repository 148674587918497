import { useContext, useState, useEffect } from "react";
import { StationContext } from "../context/Station/StationProvider";
import useDBUtilsCalls from "../utils/dbUtilsFunctions";
import HiddenBarcodeReader from "../feature/Stopwatch/HiddenBarcodeReader";
import Alert from "../feature/Stopwatch/Alert";
import Clock from "../feature/Checkpoint/Clock";
import useAnimations from "../hooks/useAnimations";
import { useNavigate } from "react-router-dom";
import useCheckpoint from "../feature/Checkpoint/useCheckpoint";
import useCheckpointCalls from "../feature/Checkpoint/useCheckpointCalls";

function Checkpoint() {
  const [alert, setAlert] = useState({ msg: "", type: "" });
  const [stationState, stationDispatch] = useContext(StationContext);
  const { state, dispatch } = useCheckpoint();
  const { postCheckpoint } = useCheckpointCalls();
  const { getEmployees } = useDBUtilsCalls();

  const navigate = useNavigate();

  const checkCurrentStation = () => {
    const stationName = window.localStorage.getItem("stationName");
    const stationId = window.localStorage.getItem("stationId");
    return stationName !== "" && stationId !== "" ? true : false;
  };

  useEffect(() => {
    if (stationState.id === "") {
      const savedStation = checkCurrentStation();
      if (savedStation) {
        stationDispatch({
          type: "station",
          name: window.localStorage.getItem("stationName"),
          id: window.localStorage.getItem("stationId"),
        });
      } else {
        navigate("/list");
      }
    }
  }, [stationState, navigate, stationDispatch]);

  const {
    animationState,
    animateStopWatch,
    animateScan,
    animateEmp,
    animateSuccess,
    animateError,
  } = useAnimations();

  const scanEmployee = (scan, emp) => {
    for (let i = 0; i < emp.length; i++) {
      if (scan === emp[i].empId) {
        const payload = {
          ...state,
          clockState: { ...state.clockState, login: true },
          employee: {
            id: `${emp[i].empId}`,
            name: `${emp[i].firstName} ${emp[i].lastName}`,
          },
          scan: "",
        };
        setAlert({ msg: "", type: "" });
        animateEmp();
        animateSuccess();
        dispatch({ type: "state", payload: payload });
        return;
      } else if (i === emp.length - 1) {
        const msg = "Invalid Employee Id";
        setAlert({ msg: msg, type: "alert-error" });
        animateError();
        dispatch({ type: "scan", payload: "" });
      }
    }
  };

  const mapProductInfo = (info) => {
    const { steps } = info;
    const list = steps.map((step) => {
      return {
        station: step.station,
        text: step.text,
      };
    });
    return list;
  };

  const generateBodyData = () => {
    const milli = Date.now();
    const date = new Date(milli);
    const now = date.toISOString();
    const bodyData = {
      lastScan: state.currentItem.id,
      scan: state.scan,
      time: now,
      empId: state.employee.id,
      empName: state.employee.name,
      stationName: stationState.name,
      stationId: stationState.id,
    };
    return bodyData;
  };

  const sendCheckPoint = async () => {
    const bodyData = generateBodyData();
    const data = postCheckpoint(bodyData);
    return data;
  };

  const TimeStamp = () => {
    const now = Date.now();
    const date = new Date(now);
    let suffix = "AM";
    let hours = parseInt(date.getHours());
    if (hours > 12) {
      hours = hours - 12;
      suffix = "PM";
    } else if (hours === 0) {
      hours = 12;
    }
    const timeString = `${hours}:${date.getMinutes()}:${date.getSeconds()} ${suffix}`;
    return timeString;
  };

  const loginScan = async () => {
    const emp = await getEmployees();
    scanEmployee(state.scan, emp);
  };

  const itemCheckPoint = async () => {
    const data = await sendCheckPoint();
    if (data.error === false) {
      if (data.logOut === true) {
        // Logout
        animateScan();
        animateSuccess();
        setAlert({ msg: "", type: "" });

        const payload = {
          ...state,
          clockState: { ...state.clockState, login: false },
          clock: "00:00:00",
          scan: "",
          employee: { id: "", name: "Scan name to sign in" },
          productInfo: [],
          currentItem: { id: "", name: "", sku: "" },
          previousItem: { id: "", name: "", sku: "" },
          sessionCount: 0,
        };
        dispatch({ type: "state", payload: payload });
      } else {
        // Stamp Time
        setAlert({ msg: "", type: "" });
        animateScan();
        animateSuccess();
        animateStopWatch();
        const sessionCount = state.sessionCount + 1;
        const payload = {
          ...state,
          clockState: { ...state.clockState, tock: true },
          clock: `${data.msg} ${TimeStamp()}`,
          scan: "",
          sessionCount: sessionCount,
          previousItem: state.currentItem,
          currentItem: {
            id: data.item.barcodeId,
            name: `${data.item.abbr} ${data.item.name}`,
            sku: data.item.sku,
          },
          productInfo: mapProductInfo(data.item),
        };
        dispatch({ type: "state", payload: payload });
      }
    } else {
      setAlert({ msg: data.msg, type: "alert-error" });
      animateError();
      dispatch({ type: "scan", payload: "" });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (state.clockState.login === false) {
      loginScan();
    } else if (state.clockState.login === true) {
      itemCheckPoint();
    }
  };

  return (
    <div className="column">
      <div className="column almostfull list shadow">
        <div className="bar split soft item">
          <h1 className="item">{stationState.name}</h1>
          <div className="column c-right">
            <h3 className="item">User</h3>
            <h5 className="item">{state.employee?.name}</h5>
          </div>
        </div>
        <Alert msg={alert.msg} type={alert.type} />
        <HiddenBarcodeReader
          handleOnSubmit={handleOnSubmit}
          scan={state.scan}
          dispatch={dispatch}
        />
        <Clock
          timer={state.clock}
          animationState={animationState}
          width={"100%"}
        />
        <section className="bar list split">
          <div className="column c-left">
            <h2 className="item">Current Item</h2>
            <h5 className="item">Name: {state.currentItem?.name}</h5>
            <h5 className="item">Sku: {state.currentItem?.sku}</h5>
          </div>
          <div className="column c-right">
            <h5 className="item">Previous Item</h5>
            <h6 className="item">{state.previousItem?.sku}</h6>
          </div>
        </section>

        <section className="bar list split">
          <div className="column c-left">
            <h2 className="item ">Product Info</h2>
            <ul>
              {state.productInfo?.map((info, index) => {
                return (
                  <li key={index}>
                    {info.station}
                    <ul>
                      <li>{info.text}</li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column c-right">
            <h5 className="item">Session Count</h5>
            <h6 className="item ">{state?.sessionCount}</h6>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Checkpoint;
