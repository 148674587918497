import useIntercept from "../../context/User/useIntercept";

function useListCalls() {
  const axiosProtected = useIntercept();

  const getStations = async () => {
    try {
      const { data } = await axiosProtected.get("list/stations");
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  return { getStations };
}

export default useListCalls;
