function Steps(props) {
  const { page } = props;

  const renderLog = (step) => {
    page.set("body.log.current", {
      ...page.state.body.log.current,
      ...step,
    });
    page.set("body.steps.show", false);
    page.set("body.log.show", true);
  };

  return (
    <div className="column">
      <h1>{page.state.body.log.current.productName}</h1>
      <h3>Steps</h3>
      <div>
        <button
          className="btn-default btn-factory item shadow"
          onClick={() => {
            page.set("body.steps.show", false);
            page.set("body.products.show", true);
          }}
        >
          🞀🞀 Products
        </button>

        {page.state.stores.processSteps.store.map((step, index) => {
          return step.constant === 0 ? (
            <button
              key={step.id}
              className="btn-default btn-factory item shadow card"
              onClick={() => {
                renderLog(step);
              }}
            >
              <div className="list">{`No.${index + 1}`}</div>
              <b>{step.name}</b>
            </button>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
}
export default Steps;
