import { reducer, initialState } from "./stationReducer";
import { createContext, useReducer } from "react";

export const StationContext = createContext({
  state: initialState,
});

export const StationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StationContext.Provider value={[state, dispatch]}>
      {children}
    </StationContext.Provider>
  );
};
