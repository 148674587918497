import useServer from "../../hooks/useServer";
function Jobs(props) {
  const { page, employee } = props;
  const { post } = useServer();

  const jobs = page.state.stores.jobs.store;

  const go_to_job = async (job) => {
    const response = await post("/employee/job/select", { job: job });
    const response2 = await post("/customers/find/products", {
      customerId: job.customerId,
    });
    page.init("stores.process", response);
    page.init("stores.products.store", response2);
    page.init("stores.processSteps.store", response.steps);
    const step = response.steps.find((step) => step._id === job.stepId);
    page.set("body.log.current", {
      ...page.state.body.log.current,
      ...step,
      productId: job.productId,
      processId: response._id,
    });
    page.set("body.welcome.show", false);
    page.set("body.products.show", false);
    page.set("body.customers.show", false);
    page.set("body.jobs.show", false);
    page.set("body.log.show", true);
  };

  return (
    <div className="column soft list">
      <h1>{`Jobs: ${jobs?.length} assigned`}</h1>

      {jobs?.map((job, index) => {
        if (index < page.state.body.show) {
          return (
            <div
              key={index}
              className="btn-job bar split soft shadow list"
              onClick={() => {
                go_to_job(job);
              }}
            >
              <div className="item">{`Batch: ${job.batch} - "${job.product}"`}</div>

              <div className="item">{`Step ${job.step}`}</div>
            </div>
          );
        }
      })}
      {jobs?.length > page.state.body.show ? (
        <button
          className="btn-factory btn-default shadow list"
          onClick={() => {
            page.set("body.show", page.state.body.show + 5);
          }}
        >
          Show More
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
export default Jobs;
