import { Routes, Route } from "react-router-dom";
import Header from "./components/headers/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import List from "./pages/List";
import "./ink.css";
import { StationProvider } from "./context/Station/StationProvider";
import StopWatch from "./pages/Stopwatch";
import Checkpoint from "./pages/Checkpoint";
import Touchlog from "./pages/Touchlog";
import Layout from "./pages/Layout";

import UserRefresh from "./context/User/UserRefresh";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" />
        <Route path="*" element={<h1 className="column soft">404</h1>} />
        <Route element={<UserRefresh />}>
          <Route element={<Header />}>
            <Route path="/" element={<Home />} />
            <Route
              path="/list"
              element={
                <StationProvider>
                  <List />
                </StationProvider>
              }
            />
            <Route
              path="/stopwatch"
              element={
                <StationProvider>
                  <StopWatch />
                </StationProvider>
              }
            />
            <Route
              path="/checkpoint"
              element={
                <StationProvider>
                  <Checkpoint />
                </StationProvider>
              }
            />
            <Route
              path="/touchlog"
              element={
                <StationProvider>
                  <Touchlog />
                </StationProvider>
              }
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
