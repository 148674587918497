import usePage from "../../hooks/usePage";
import { useEffect } from "react";
import useServer from "../../hooks/useServer";
import useAuth from "../../context/User/useAuth";
function useHome() {

  const employee = useAuth().auth.employee;


  const initialState = {
    body: {
      show: 2,
      welcome: {
        show: true,
      },
      jobs: {
        show: true,
      },
      customers: {
        show: true,
      },
      products: {
        show: false,
      },
      steps: {
        show: false,
      },
      log: {
        show: false,
        logged: "",
        scan: "",
        current: {},
        pause: false,
        clockState: { login: false, tock: false },
        seconds: 0,
        sessionCount: 0,
        clock: "00:00:00",
      },
    },
    stores: {
      jobs: { store: [] },
      customers: { store: [] },
      products: { store: [] },
      process: {},
      processSteps: { store: [] },
    },
  };

  const signalState = {};

  const page = usePage(initialState, signalState);
  const { load, init, set } = page;
  const { get, post } = useServer();

  //Customer
  useEffect(() => {
    async function effect() {
      load({
        crud: async () => {
          return get("/customers");
        },
        on: "stores.customers",
        set: (res) => {
          init("stores.customers.store", res);
        },
      });
    }
    effect();
  }, [get, load, init]);

  //Jobs
  useEffect(() => {
    async function effect() {

      load({
        crud: async () => {
          return post("/jobs", employee._id);
        },
        on: "stores.jobs",
        set: (res) => {
          init("stores.jobs.store", res);
        },
      });
    }
    effect();
  }, [post, load, employee, init]);

  return page;
}
export default useHome;
