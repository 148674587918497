import useIntercept from "../../context/User/useIntercept";

function useCheckpointCalls() {
  const axiosProtected = useIntercept();

  const postCheckpoint = async (req) => {
    const { data } = await axiosProtected.post("checkpoint/scan", {
      data: req,
    });
    return data;
  };
  return { postCheckpoint };
}

export default useCheckpointCalls;
