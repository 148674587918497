//Iterate over the initial state object and add an _id property to each object that corresponds
// to the path of the object in the state tree. This will allow us to easily find the object

String.prototype.customHash = function () {
  let hash = 5381;
  for (let i = 0; i < this.length; i++) {
    let char = this.charCodeAt(i);
    hash = (hash * 33) ^ char;
  }
  return hash >>> 0; // Ensure non-negative integer
};

function unsetProperties(object) {
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value !== "object" && /error_/.test(key)) {
      delete object[key];
    }
  });

  delete object.index;
  delete object.render;
  delete object.status;
  delete object.loading;
  delete object.initialized;
  delete object.path;
  delete object.id;
}

function breadthFirstUnset(object) {
  const queue = [object];

  while (queue.length > 0) {
    const object = queue.shift();

    if (typeof object === "object" && object !== null) {
      const init = object?.initialized ?? false;
      if (init) {
        unsetProperties(object);
      }

      const keys = Object.keys(object);

      keys.forEach((key) => {
        if (typeof object[key] === "object" && !Array.isArray(object[key])) {
          queue.push(object[key]);
        } else if (Array.isArray(object[key])) {
          object[key].forEach((item) => {
            queue.push(item);
          });
        }
      });
    }
  }

  return object;
}

function setProperties(object, currentPath, index) {
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value !== "object" && key !== `error_${key}`) {
      let name = `error_${key}`;
      Object.defineProperty(object, name, {
        value: false,
        enumerable: true,
        writable: true,
        configurable: true,
      });
    }
  });

  Object.assign(object, {
    index: index, // consider removing this
    render: 0,
    status: "idle",
    loading: false,
    // signal: true,
  });

  Object.defineProperties(object, {
    initialized: {
      value: true,
      enumerable: true,
      writable: false,
      configurable: true,
    },
    path: { value: currentPath, enumerable: true, configurable: true },
    id: {
      value: currentPath.toString().customHash(),
      enumerable: true,
      writable: false,
      configurable: true,
    },
  });
}

function breadthFirst(object, path = "") {
  const queue = [{ object: object, path: path, index: 0 }];

  while (queue.length > 0) {
    const { object, path, index } = queue.shift();

    if (typeof object === "object" && object !== null) {
      const init = object?.initialized ?? false;
      if (path !== "" && !init) {
        setProperties(object, path, index);
      }

      const keys = Object.keys(object);

      keys.forEach((key) => {
        const currentPath = path !== "" ? `${path}.${key}` : key;
        if (typeof object[key] === "object" && !Array.isArray(object[key])) {
          queue.push({ object: object[key], path: currentPath, index });
        } else if (Array.isArray(object[key])) {
          object[key].forEach((item, index) => {
            queue.push({
              object: item,
              path: `${currentPath}.${index}`,
              index,
            });
          });
        }
      });
    }
  }

  return object;
}

function initialize(object, path, reinitialize = false) {
  if (reinitialize) {
    console.log("reinitialize");
    //TODO:: this is not modifying the original object so it is not working
    // object = JSON.parse(JSON.stringify(object));
    breadthFirstUnset(object);
  }
  breadthFirst(object, path);
  return object;
}
export default initialize;
