import { useContext, useState, useEffect, useCallback } from "react";
import { StationContext } from "../context/Station/StationProvider";
import useDBUtilsCalls from "../utils/dbUtilsFunctions";
import HiddenBarcodeReader from "../feature/Stopwatch/HiddenBarcodeReader";
import Timer from "../feature/Stopwatch/Timer";
import Alert from "../feature/Stopwatch/Alert";
import useAnimations from "../hooks/useAnimations";
import { useNavigate } from "react-router-dom";
import useStopWatch from "../feature/Stopwatch/useStopWatch";
import useCheckpointCalls from "../feature/Stopwatch/stopwatchAxiosFunctions";

function StopWatch() {
  const [alert, setAlert] = useState({ msg: "", type: "" });
  const { state, dispatch } = useStopWatch();
  const [stationState, stationDispatch] = useContext(StationContext);

  const { getEmployees } = useDBUtilsCalls();
  const { postScan } = useCheckpointCalls();
  const navigate = useNavigate();

  const {
    animationState,
    animateStopWatch,
    animateScan,
    animateEmp,
    animateSuccess,
    animateError,
  } = useAnimations();

  const scanEmployee = (scan, emp) => {
    for (let i = 0; i < emp.length; i++) {
      if (scan === emp[i].empId) {
        const payload = {
          ...state,
          clockState: { login: true, tock: false },
          scan: "",
          clock: "00:00:00",
          seconds: 0,
          employee: {
            id: `${emp[i].empId}`,
            name: `${emp[i].firstName} ${emp[i].lastName}`,
          },
          currentItem: { id: "", name: "", sku: "" },
          previousItem: { id: "", name: "", sku: "" },
          productInfo: [],
          sessionCount: 0,
        };
        dispatch({ type: "state", payload: payload });
        setAlert({ msg: "", type: "" });
        animateEmp();
        animateSuccess();
        return;
      } else if (i === emp.length - 1) {
        const msg = "Invalid Employee Id";
        setAlert({ msg: msg, type: "alert-error" });
        animateError();
        dispatch({ type: "scan", payload: "" });
      }
    }
  };

  const mapProductInfo = (info) => {
    const { steps } = info;
    const list = steps.map((step) => {
      return {
        station: step.station,
        text: step.text,
      };
    });
    return list;
  };

  const generateBodyData = () => {
    const milli = Date.now();
    const date = new Date(milli);
    const now = date.toISOString();
    const bodyData = {
      lastScan: state.currentItem.id,
      scan: state.scan,
      time: now,
      empId: state.employee.id,
      empName: state.employee.name,
      stationName: stationState.name,
      stationId: stationState.id,
    };
    return bodyData;
  };

  const sendScan = async () => {
    const bodyData = generateBodyData();
    const data = await postScan(bodyData);
    return data;
  };

  //Login Scan is general across components
  const loginScan = async () => {
    const emp = await getEmployees();
    scanEmployee(state.scan, emp);
  };

  // Send the Scan, check for logout and refresh the clock.
  const itemScan = async () => {
    const data = await sendScan();
    if (data.error === false) {
      if (data.logOut === true) {
        // Logout
        animateScan();
        animateSuccess();
        setAlert({ msg: "", type: "" });
        dispatch({ type: "reset" });
      } else {
        // Reset Clock
        setAlert({ msg: "", type: "" });
        animateScan();
        animateSuccess();
        animateStopWatch();
        const item = data.item;
        const sessionCount = state.sessionCount + 1;
        const payload = {
          ...state,
          clockState: { ...state.clockState, tock: true },
          scan: "",
          clock: "00:00:00",
          seconds: 0,
          sessionCount: sessionCount,
          previousItem: state.currentItem,
          currentItem: {
            id: item.barcodeId,
            name: `${item.abbr} ${item.name}`,
            sku: item.sku,
          },
          productInfo: mapProductInfo(item),
        };
        dispatch({ type: "state", payload: payload });
      }
    } else {
      setAlert({ msg: data.msg, type: "alert-error" });
      animateError();
      dispatch({ type: "scan", payload: "" });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (state.clockState.login === false) {
      loginScan();
    } else if (state.clockState.login === true) {
      itemScan();
    }
  };

  const checkCurrentStation = () => {
    const stationName = window.localStorage.getItem("stationName");
    const stationId = window.localStorage.getItem("stationId");
    return stationName !== "" && stationId !== "" ? true : false;
  };

  useEffect(() => {
    if (stationState.id === "") {
      const savedStation = checkCurrentStation();
      if (savedStation) {
        stationDispatch({
          type: "station",
          name: window.localStorage.getItem("stationName"),
          id: window.localStorage.getItem("stationId"),
        });
      } else {
        navigate("/list");
      }
    }
  }, [stationState, navigate, stationDispatch]);

  const callbackTick = useCallback(() => {
    const tick = (now) => {
      let remain = now;
      let hours = Math.floor(remain / 3600);
      remain -= hours * 3600;
      let mins = Math.floor(remain / 60);
      remain -= mins * 60;
      let secs = remain;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (mins < 10) {
        mins = "0" + mins;
      }
      if (secs < 10) {
        secs = "0" + secs;
      }
      dispatch({ type: "clock", payload: `${hours}:${mins}:${secs}` });
    };
    tick(state.seconds);
  }, [dispatch, state.seconds]);

  // Start Clock
  useEffect(() => {
    let interval = null;
    if (state.clockState.tock) {
      interval = setInterval(() => {
        let next = state.seconds + 1;
        dispatch({ type: "seconds", payload: next });
        // tick(state.seconds);
        callbackTick();
      }, 1000);
    } else if (!state.clockState.tock && state.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [state.clockState, state.seconds, callbackTick, dispatch]);

  // useEffect(() => {
  //   let interval = null;
  //   if (state.clockState.tock) {
  //     interval = setInterval(() => {
  //       let next = state.seconds + 1;
  //       dispatch({ type: "seconds", payload: next });
  //       tick(state.seconds);
  //     }, 1000);
  //   } else if (!state.clockState.tock && state.seconds !== 0) {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [state.clockState, state.seconds]);

  // const tick = (now) => {
  //   let remain = now;
  //   let hours = Math.floor(remain / 3600);
  //   remain -= hours * 3600;
  //   let mins = Math.floor(remain / 60);
  //   remain -= mins * 60;
  //   let secs = remain;

  //   if (hours < 10) {
  //     hours = "0" + hours;
  //   }
  //   if (mins < 10) {
  //     mins = "0" + mins;
  //   }
  //   if (secs < 10) {
  //     secs = "0" + secs;
  //   }
  //   dispatch({ type: "clock", payload: `${hours}:${mins}:${secs}` });
  // };

  return (
    <div className="column">
      <div className="column almostfull list shadow">
        <div className="bar split soft item">
          <h1 className="item">{stationState.name}</h1>
          <div className="column c-right">
            <h3 className="item">User</h3>
            <h5 className="item">{state.employee.name}</h5>
          </div>
        </div>
        <Alert msg={alert.msg} type={alert.type} />
        <HiddenBarcodeReader
          handleOnSubmit={handleOnSubmit}
          scan={state.scan}
          dispatch={dispatch}
        />
        <Timer
          timer={state.clock}
          animationState={animationState}
          width={"100%"}
          action={() => {}}
        />
        <section className="bar list split">
          <div className="column c-left">
            <h2 className="item">Current Item</h2>
            <h5 className="item">Name: {state.currentItem.name}</h5>
            <h5 className="item">Sku: {state.currentItem.sku}</h5>
          </div>
          <div className="column c-right">
            <h5 className="item">Previous Item</h5>
            <h6 className="item">{state.previousItem.sku}</h6>
          </div>
        </section>

        <section className="bar list split">
          <div className="column c-left">
            <h2 className="item ">Product Info</h2>
            <ul>
              {state.productInfo.map((info, index) => {
                return (
                  <li key={index}>
                    {info.station}
                    <ul>
                      <li>{info.text}</li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column c-right">
            <h5 className="item">Session Count</h5>
            <h6 className="item ">{state.sessionCount}</h6>
          </div>
        </section>
      </div>
    </div>
  );
}
export default StopWatch;
